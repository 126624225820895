import { FormEvent, ReactElement } from "react";
import ApplicationLogo from "../ApplicationLogo";
import "./form.scss";
import { Link } from "@inertiajs/react";

export default function Form({
    children,
    title,
    subHeading,
    className,
    status,
    ziggy,
    app_logo,
    onSubmit,
}: {
    children: any;
    title?: string;
    subHeading?: string;
    className: string;
    status?: string;
    ziggy?: any;
    app_logo?: string;
    onSubmit: Function;
}): ReactElement {
    return (
        <section className="form-section">
            <div className="container">
                <form
                    className={`form-container ${className}`}
                    onSubmit={(e: FormEvent) => {
                        e.preventDefault();
                        onSubmit();
                    }}
                >
                    <div className="form-logo">
                        <Link href={route("home", { clinic: ziggy.clinic })}>
                            <ApplicationLogo app_logo={app_logo} />
                        </Link>
                    </div>

                    <div className="form-content">
                        <div className="form-title">
                            <p className="p-md">{title}</p>
                        </div>

                        {subHeading && (
                            <p className="p sub-heading">{subHeading}</p>
                        )}
                        {children}
                    </div>
                    {status && <p className="p status">{status}</p>}
                </form>
            </div>
        </section>
    );
}
